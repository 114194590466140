/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import moment from "moment";
import { ArticleList, Footer, Go, Grid } from "~components";
import { useAuth, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { sortArrayByNumberProperty } from "~utils/helpers";

import { ReactComponent as CategoryCommunity } from "~assets/icons/community-icon.svg";
import { ReactComponent as CategoryCultivate } from "~assets/icons/cultivate-icon.svg";
import { ReactComponent as CategoryGrowth } from "~assets/icons/growth-icon.svg";
import { ReactComponent as CategoryReplenish } from "~assets/icons/replenish-icon.svg";
import { ReactComponent as CategoryTheLand } from "~assets/icons/the-land-icon.svg";
import { ReactComponent as CategoryToolkit } from "~assets/icons/toolkit-icon.svg";
import { ReactComponent as Message } from "~assets/icons/message.svg";

const Dashboard = ({ data, location }) => {
  // ==========================================================================
  // context / ref / state

  const { authUser, account } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const { allSanityArticle, allSanityCategory, allSanityEvent } =
    useStaticQuery(graphql`
      query DashboardPage {
        allSanityArticle(sort: { order: DESC, fields: [date, priority] }) {
          edges {
            node {
              _id

              priority
              availability
              tokenKey
              cost
              date
              title
              excerpt
              readTime

              slug {
                current
              }

              theme {
                backgroundColour {
                  hex
                }
                foregroundColour {
                  hex
                }
                buttonColour
              }

              type {
                title
              }

              cardImage {
                asset {
                  gatsbyImageData(width: 1200, placeholder: NONE)
                }
                altText
              }
            }
          }
        }

        allSanityEvent(sort: { order: DESC, fields: [date, priority] }) {
          edges {
            node {
              _id

              date
              priority
              title
              excerpt

              slug {
                current
              }

              theme {
                backgroundColour {
                  hex
                }
                foregroundColour {
                  hex
                }
                buttonColour
              }

              cardImage {
                asset {
                  gatsbyImageData(width: 1200, placeholder: NONE)
                }
                altText
              }
            }
          }
        }

        allSanityCategory {
          edges {
            node {
              slug {
                current
              }

              name
            }
          }
        }
      }
    `);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedArticles, setSortedArticles] = useState([]);

  // ==========================================================================
  // variables

  const iconCSS = [tw`h-2/3 relative`];

  const icons = {
    community: (
      <CategoryCommunity
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    ),
    cultivate: (
      <CategoryCultivate
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    ),
    growth: (
      <CategoryGrowth
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    ),
    replenish: (
      <CategoryReplenish
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    ),
    "the land": (
      <CategoryTheLand
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    ),
    toolkit: (
      <CategoryToolkit
        css={iconCSS}
        fill={lowImpactMode ? colours.foreground : colours.background}
      />
    )
  };

  // ==========================================================================
  // lifecycle

  useEffect(() => {
    if (account && loading) {
      setLoading(false);
    }
  }, [account]);

  useEffect(() => {
    if (sortedArticles?.[0]) {
      return;
    }

    const articles = allSanityArticle.edges.map(({ node }) => node);

    const events = allSanityEvent.edges.map(({ node }) => ({
      type: {
        title: `event`
      },
      ...node
    }));

    const dashboardItems = [...articles, ...events].sort((a, b) => {
      const dateA = a?.date ? moment(a.date) : moment(`01/01/2020`);
      const dateB = b?.date ? moment(b.date) : moment(`01/01/2020`);

      if (dateA > dateB) {
        return -1;
      }

      if (dateA < dateB) {
        return 1;
      }

      return 0;
    });

    setSortedArticles(dashboardItems);
  }, [allSanityArticle, allSanityCategory, allSanityEvent]);

  useEffect(() => {
    if (categories?.[0] || !allSanityCategory?.edges?.[0]) {
      return;
    }

    setCategories(allSanityCategory.edges.map(({ node }) => node));
  }, [allSanityCategory]);

  // ==========================================================================
  // render

  // todo: redirect?
  if (!authUser?.email) {
    return <></>;
  }

  return (
    <>
      <section
        css={[
          css`
            ${colourTransitionCSS};

            background: ${lowImpactMode
              ? colours.foreground
              : colours.background};
            color: ${lowImpactMode ? colours.background : colours.foreground};
          `,
          tw`relative pt-48`
        ]}
      >
        <Grid>
          <header css={[tw`col-span-12 md:col-span-10 md:col-start-2`]}>
            {(account?.firstName && (
              <>
                {!loading && (
                  <h1
                    css={[
                      css`
                        animation: 0.5s ease appear-up forwards;
                        animation-delay: 0.25ms;
                        transform: translate3d(0, 1rem, 0);
                        opacity: 0;
                      `,
                      tw`font-head text-h2-md`
                    ]}
                  >
                    Hi {account.firstName},
                  </h1>
                )}
              </>
            )) || (
              <h1
                css={[
                  css`
                    animation: 2s ease appear-up forwards;
                    transform: translate3d(0, 1rem, 0);
                    opacity: 0;
                  `,
                  tw`font-head text-h2-md`
                ]}
              >
                Fetching account...
              </h1>
            )}

            <h2
              css={[
                css`
                  animation: 0.5s ease appear-up forwards;
                  transform: translate3d(0, 1rem, 0);
                  opacity: 0;
                `,
                tw`relative block mt-6 font-inline text-h4-md`
              ]}
            >
              DISCOVER HOW YOU CAN GROW
            </h2>
          </header>

          <div
            css={[
              tw`col-span-12 md:col-span-10 md:col-start-2 relative mt-5 pb-24`
            ]}
          >
            <ul
              css={[
                css`
                  min-height: 14vw;
                `,
                tw`w-full relative flex`
              ]}
            >
              {categories.map((category, categoryIndex) => (
                <li
                  css={[
                    css`
                      animation: 0.5s ease appear-up forwards;
                      animation-delay: ${categoryIndex * 50}ms;
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                    `
                  ]}
                  key={`dashboard-category-${category?.name}`}
                >
                  <Go
                    _css={[
                      css`
                        transition: 0.3s ease opacity;

                        display: block;

                        ${MEDIA_QUERIES.hoverable} {
                          &:hover {
                            opacity: 0.8;
                          }
                        }
                      `
                    ]}
                    to={`/categories/${category?.slug?.current}`}
                  >
                    <div
                      css={[
                        css`
                          background: ${lowImpactMode
                            ? colours.background
                            : colours.foreground};
                          color: ${lowImpactMode
                            ? colours.foreground
                            : colours.background};
                        `,
                        tw`w-24 relative flex flex-col items-center justify-center mr-4 pt-2 pr-4 pb-4 pl-4 rounded-xl`
                      ]}
                    >
                      <div
                        css={[tw`w-full relative rounded-lg overflow-hidden`]}
                      >
                        <div css={[tw`w-full relative block pb-[100%]`]}>
                          <div
                            css={[
                              tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center`
                            ]}
                          >
                            {icons?.[category?.name?.toLowerCase()]}
                          </div>
                        </div>
                      </div>

                      <h3
                        css={[
                          tw`relative block mt-1 whitespace-nowrap font-body text-b3-md`
                        ]}
                      >
                        {category?.name}
                      </h3>
                    </div>
                  </Go>
                </li>
              ))}
            </ul>

            <Go
              to="https://app.geneva.com/home/9271a402-c9b8-4c79-a363-73ada19ee131/room/f4fb3202-0dae-4933-af78-17786168b461?inviteId=272987e7-649e-49fe-aff2-4521f886a31e"
              _css={[
                css`
                  right: -8vw;
                  padding: 2vw 1vw 1.5vw;
                  border-radius: 24px;
                  position: relative;

                  background: ${colours.background};
                  color: ${colours.foreground};
                  border: 1px solid ${colours.foreground};
                `,
                tw`absolute top-0 flex flex-col items-center justify-center border text-center`
              ]}
              newTab
            >
              <Message
                css={[
                  css`
                    width: 4vw;
                  `,
                  tw`relative block`
                ]}
                fill={colours.foreground}
              />
              <p css={[tw`mt-5 font-body text-b2-md`]}>Join us on Geneva</p>
            </Go>
          </div>
        </Grid>
      </section>

      <div css={[tw`relative bg-grey-tint`]}>
        <ArticleList articles={sortedArticles} withHeading />
      </div>

      <Footer />
    </>
  );
};

export default Dashboard;
